<script>
	import { tweened } from "svelte/motion";
	import { circOut } from "svelte/easing";

	export let doneCount, allCount, colors;
	let pct = tweened(0, {
		duration: 1000,
		delay: 500,
		easing: circOut,
	});

	$: pct.set((doneCount / allCount) * 100);
</script>

<div class="flex items-center justify-center">
	<div
		class="absolute h-32 w-32 overflow-visible md:h-40 md:w-40 lg:h-32 lg:w-32 xl:h-40 xl:w-40"
	>
		<svg class="overflow-visible" viewBox="0 0 100 100">
			{#if $pct == 100}
				<circle
					class={colors.bg}
					cx="50"
					cy="50"
					fill="none"
					r="46"
					stroke="currentColor"
					stroke-width="20"
				/>
			{:else if $pct > 4}
				<circle
					class={colors.bg}
					cx="50"
					cy="50"
					fill="none"
					r="46"
					stroke="currentColor"
					stroke-dasharray="{($pct * 289) / 100},1000"
					stroke-dashoffset="0"
					stroke-width="12"
					transform="rotate(-90) translate(-100)"
				/>

				<circle
					class={colors.bg}
					cx="50"
					cy="50"
					fill="none"
					r="52"
					stroke="currentColor"
					stroke-dasharray="{($pct * 327) / 100 - 8},1000"
					stroke-dashoffset="-4"
					stroke-linecap="round"
					stroke-width="8"
					transform="rotate(-90) translate(-100)"
				/>

				<circle
					class={colors.bg}
					cx="50"
					cy="50"
					fill="none"
					r="40"
					stroke="currentColor"
					stroke-dasharray="{($pct * 251.3) / 100 - 8},1000"
					stroke-dashoffset="-4"
					stroke-linecap="round"
					stroke-width="8"
					transform="rotate(-90) translate(-100)"
				/>
			{/if}
		</svg>
	</div>
	<div
		class="flex h-32 w-32 items-center justify-center rounded-full border-[12px] border-neutral-100 md:h-40 md:w-40 lg:h-32 lg:w-32 xl:h-40 xl:w-40"
	>
		<p class="text-4xl font-semibold {colors.text}">
			{$pct.toFixed(0)}%
		</p>
	</div>
</div>
